/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import styled from 'styled-components';

import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import ArticlePreview from '../components/_blog/ArticlePreview';
import ButtonReadMore from '../components/_blog/Button/ReadMore';
import ChatbotDesc from '../components/_blog/ChatbotDesc';
import useDetectIntl from '../hooks/useDetectIntl';
import Page404 from '../pages/404';

const Title = styled.h1`
	font-family: Montserrat, 'Times New Roman', Serif;
	font-style: normal;
	font-weight: bold;
	font-size: 36px;
	line-height: 140%;
	color: #4f4f4f;
`;

const ListPostByCategory = ({ data, location, pageContext }) => {
	const [readMore, setReadMore] = useState(false);
	const { isIntl } = useDetectIntl();
	const { seoTitle, seoDesc, title } = pageContext;
	const siteTitle = get(data, 'site.siteMetadata.title');
	const posts = get(data, 'allContentfulBlogPost.edges');
	const firstLimitPost = posts.slice(0, 4);
	const otherPosts = posts.slice(4);
	const currentLocation = location.href;
	let isNoIndex = true;

	if (isIntl) {
		return <Page404 />;
	}

	if (currentLocation) {
		if (currentLocation.split('/').includes('portofolio')) {
			isNoIndex = true;
		}
		if (currentLocation.split('/').includes('bisnis')) {
			isNoIndex = true;
		}
		if (currentLocation.split('/').includes('artificialintelligence')) {
			isNoIndex = true;
		}
		if (currentLocation.split('/').includes('chatbot')) {
			isNoIndex = true;
		}
		if (currentLocation.split('/').includes('customer-service')) {
			isNoIndex = true;
		}
	}

	const renderReadMore = () => {
		if (otherPosts.length > 0 && !readMore) {
			return (
				<ButtonReadMore
					onClick={() => {
						setReadMore(true);
					}}
				/>
			);
		}

		if (otherPosts.length > 0 && readMore) {
			return otherPosts.map(({ node }) => {
				return (
					<div
						key={node.slug}
						className="col-12 col-sm-12 col-md-12 col-lg-10 article-preview-hamburger"
					>
						<ArticlePreview article={node} />
					</div>
				);
			});
		}

		return <></>;
	};

	return (
		<Layout location={location}>
			<div style={{ background: '#fff' }} className="container">
				<Helmet title={siteTitle}>
					<html lang="id" />
					<title>{seoTitle || ''}</title>
					<meta name="description" content={seoDesc || ''} />
					{isNoIndex || isIntl ? (
						<meta name="robots" content="noindex, nofollow" />
					) : (
						<meta name="robots" content="index, follow" />
					)}
				</Helmet>

				<div className="featured-posts">
					<div className="section-title">
						<Title>{title}</Title>
					</div>

					<section className="recent-posts">
						<div className="row">
							{firstLimitPost.map(({ node }) => {
								return (
									<div
										key={node.slug}
										className="col-12 col-sm-12 col-md-12 col-lg-10 article-preview-hamburger"
									>
										<ArticlePreview article={node} />
									</div>
								);
							})}
							{renderReadMore()}
						</div>
					</section>
				</div>
			</div>
			<ChatbotDesc />
		</Layout>
	);
};

ListPostByCategory.propTypes = {
	data: PropTypes.instanceOf(Object),
	location: PropTypes.string.isRequired,
	pageContext: PropTypes.shape({
		title: PropTypes.string,
		slug: PropTypes.string.isRequired,
		seoTitle: PropTypes.string,
		seoDesc: PropTypes.string,
	}),
};

export default ListPostByCategory;

export const pageQuery = graphql`
	query ListPostByCategoryQuery($slug: String!) {
		allContentfulBlogPost(
			filter: { category: { slug: { eq: $slug } } }
			sort: { fields: [createdAt], order: DESC }
		) {
			edges {
				node {
					title
					slug
					createdAt
					description
					cover {
						fluid(maxWidth: 400, background: "rgb:000000") {
							...GatsbyContentfulFluid_withWebp
						}
					}
					author {
						name
						cover {
							fluid(maxWidth: 50, background: "rgb:000000") {
								...GatsbyContentfulFluid_withWebp
							}
						}
					}
				}
			}
		}
	}
`;
