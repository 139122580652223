import styled from 'styled-components';

export const Wrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 50px;
	margin-bottom: 50px;
	button {
		border: none;
		background-color: transparent;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 140%;
		display: flex;
		align-items: center;
		color: #066fc2;
		align-items: center;
		justify-content: center;
		p {
			margin: 0;
			padding: 0;
		}
	}
`;

export default Wrapper;
