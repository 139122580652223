import { useState } from 'react';
// di gunakan untuk mendeteksi /en dan malekukan disable meta robot di blog
const useDetectIntl = () => {
	const currentLocation = typeof window !== 'undefined' ? window.location.pathname : '';
	const splitCurrentLocation = currentLocation.split('/');
	const [isIntl] = useState(splitCurrentLocation.includes('en'));
	return {
		isIntl,
	};
};

export default useDetectIntl;
