import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './style';
import { SVGRender } from '../../../../helper/image';

const Readmore = ({ onClick }) => {
	return (
		<>
			<Wrapper>
				<button type="button" onClick={onClick}>
					<p>Lihat Lebih Lengkap</p>
					{SVGRender(
						'https://seiturju.sirv.com/Chatbiz/arrow-expand-more.svg',
						'',
						'',
						'',
						'Expand More',
					)}
				</button>
			</Wrapper>
		</>
	);
};

Readmore.propTypes = {
	onClick: PropTypes.func.isRequired,
};

export default Readmore;
