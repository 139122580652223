import React from 'react';
import { Wrapper } from './style';
import ImageRender from '../../../helper/image';

const ChatbotDesc = () => {
	return (
		<Wrapper>
			<div className="container">
				<div className="contentWrapper">
					<div className="contentWrapper__desc">
						<h2 className="contentWrapper__desc--title">Tingkatkan Pengalaman Pelanggan Bisnis Anda </h2>
						<div className="contentWrapper__desc--text">
							<p>
								Buat layanan otomasi dengan Asisten Virtual Chatbot dari Chatbiz.id yang dapat disesuaikan
								dengan bisnis Anda, sehingga mampu membuat bisnis anda terus berkembang dengan biaya
								terjangkau.
							</p>
							<p>
								Anda dapat membuat web katalog dengan desain sesuka Anda, otomasi proses order via WhatsApp,
								rekap pesanan otomatis, dan layani pelanggan dengan chatbot 24 jam non-stop, tanpa perlu
								aplikasi tambahan.
							</p>
						</div>
						<div className="contentWrapper__desc--btn">
							<a href="/">Konsultasikan</a>
						</div>
					</div>
					<div className="contentWrapper__image">
						{ImageRender(
							'https://seiturju.sirv.com/Chatbiz/blog-desc-chatnshop.png?format=webp',
							'https://seiturju.sirv.com/Chatbiz/blog-desc-chatnshop.png?format=webp',
							'https://seiturju.sirv.com/Chatbiz/blog-desc-chatnshop.png?format=webp',
							'',
							'',
							'',
						)}
					</div>
				</div>
			</div>
			<div className="bg-circle" />
		</Wrapper>
	);
};

export default ChatbotDesc;
