/* eslint-disable react/require-default-props */
import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { convertToIdMonthDate } from '../../../helper/time';
import { trimOn } from '../../../helper/text';
import { Wrapper } from './style';

const ArticlePreview = ({ article }) => {
	const { slug, title, cover, description, author } = article;
	return (
		<Wrapper>
			<div className="card">
				<div className="row">
					<div className="col-12 col-sm-12 col-md-5 col-lg-5  thumbnailWrapper">
						<Link to={`/blog/${slug}`}>
							<Img className="thumbnail" alt={title} fluid={cover ? cover.fluid : ''} />
						</Link>
					</div>
					<div className="col-12 col-sm-12 col-md-7 col-lg-7">
						<div className="card-block">
							<h2 className="card-title">
								<Link className="libreFont boldtxt" to={`/blog/${slug}`}>
									{trimOn(title, 100)}
								</Link>
							</h2>
							<h4 className="card-desc">{trimOn(description || '', 250)}</h4>
							<div className="footer-desc">
								{convertToIdMonthDate(article.createdAt)} | {author ? author.name : ''}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Wrapper>
	);
};

ArticlePreview.propTypes = {
	article: PropTypes.instanceOf(Object),
};

export default ArticlePreview;
