import styled from 'styled-components';

const breakpoint = '1122px';

export const Wrapper = styled.section`
	background-color: #066fc2;
	position: relative;
	margin-top: 20px;
	.bg-circle {
		background: url('https://seiturju.sirv.com/Chatbiz/blog-desc-white-circle.svg');
		background-size: cover;
		width: 322px;
		height: 453px;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 9;
		@media screen and (max-width: ${breakpoint}) {
			display: none;
		}
	}
	.contentWrapper {
		display: flex;
		padding-top: 4rem;
		@media screen and (max-width: ${breakpoint}) {
			flex-direction: column-reverse;
		}
		&__desc {
			width: 70%;
			padding-right: 50px;
			@media screen and (max-width: ${breakpoint}) {
				width: 100%;
				margin-bottom: 3.75rem;
				padding-right: 0;
			}
			&--title {
				font-family: Montserrat;
				font-style: normal;
				font-weight: bold;
				font-size: 30px;
				line-height: 140%;
				color: #ffffff;
				@media screen and (max-width: ${breakpoint}) {
					margin-top: 1rem;
				}
			}
			&--text {
				font-family: Montserrat;
				font-style: normal;
				font-weight: normal;
				font-size: 20px;
				line-height: 140%;
				color: #ffffff;
				margin-top: 1.25rem;
				margin-bottom: 1.25rem;
			}
			&--btn {
				margin-top: 2.25rem;
				a {
					background: #ff8f1c;
					box-shadow: 0px 5px 12px rgba(21, 88, 121, 0.15);
					border-radius: 6px;
					font-family: Montserrat;
					font-style: normal;
					font-weight: bold;
					font-size: 16px;
					line-height: 24px;

					text-align: center;
					text-transform: capitalize;

					color: #ffffff;
					padding: 0.75rem 1.688rem;
					&:hover {
						background-color: #ffffff;
						color: #066fc2;
						text-decoration: none;
					}
				}
			}
		}
		&__image {
			width: 30%;
			z-index: 99;
			@media screen and (max-width: ${breakpoint}) {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			picture,
			img {
				min-width: 335px;
				z-index: 99;
			}
		}
	}
`;

export default Wrapper;
